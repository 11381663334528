<template>
    <div>
        <span style="width: 100%;text-align: center"> LISTE DES DDAEP </span>
    
        <v-container fluid class="down-top-padding">
            <v-card>
                <v-card-text class="pa-2">
    
                    <v-data-table :headers="headers" :items="list" sort-by="id" class="contact-listing-app" :search="search">
                        <template v-slot:top>
                            <v-toolbar flat>
    
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Recherche...." filled background-color="transparent" hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-spacer></v-spacer>
                                <v-dialog v-model="dialog" max-width="700">
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="primary" dark class="mb-2 text-capitalize" v-show="true" v-on="on" @click="close()">
                                            Nouveau
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title class="pa-4 info">
                                            <span class="title white--text">Formulaire</span>
                                        </v-card-title>
    
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field outlined hide-details v-model="editedItem.libelle_ddaep" label="Libelle de ATDA *"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field outlined hide-details v-model="editedItem.sigle" label="Sigle *"></v-text-field>
                                                    </v-col>
    
                                                </v-row>
    
                                            </v-container>
                                        </v-card-text>
    
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="error" dark @click="dialog=false">Cancel</v-btn>
                                            <v-btn color="success" dark @click="Save">Enregistrer</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
    
                            </v-toolbar>
                        </template>
    
                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="d-flex">
    
                                <v-icon large class="mb-2 mr-2 info--text" @click="getitem(item)">mdi-pencil</v-icon>
                                <v-icon large class="mb-2 mr-2 error--text" @click="deletItem(item)">mdi-delete</v-icon>
                            </div>
                        </template>
    
                        <template v-slot:no-data>
                            >
                        </template>
    
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
    </template>
    
    <script>
    //import api from '../../../serviceApi/apiService'
    import api from '@/serviceApi/apiService'
    
    export default {
        name: "ProjectList",
        data: () => ({
            dialog: false,
            search: "",
            headers: [{
                    text: "Actions",
                    align: "start",
                    sortable: false,
                    value: "actions",
                    divider: true,
                    width: '4%'
    
                },
                {
                    text: "Id",
                    align: "start",
                    sortable: false,
                    value: "id",
                    divider: true,
                    width: '5%'
                },
                {
                    text: "Libelle de l'institution",
                    align: "start",
                    sortable: false,
                    value: "libelle_ddaep",
                    divider: true,
                    width: '150px'
                },
                {
                    text: "Sigle",
                    value: "sigle",
                    divider: true,
                    width: '80px'
                },
    
            ],
            editedItem: {
                id: '',
                libelle_ddaep: '',
                sigle: '',
            },
    
            list: [],
            etat: false,
    
          
    
        }),
    
        watch: {
    
        },
    
        created() {
            //this.readAll_geographie();
            //this.readAll_Survey();
            this.readAll();
            // this.readAll_Export_Excel();
        },
        methods: {
    
            readAll: async function () {
                let fd = new FormData();
                const data = await api.createUpdatedata('admin-backoffice/all-ddaep', fd);
                this.list = data.succes;
    
            },
    
            Save: async function () {
    
                let fd = new FormData();
                fd.append('libelle_ddaep', this.editedItem.libelle_ddaep);
                fd.append('sigle', this.editedItem.sigle);
                if (this.etat == false) {
                    const data = await api.createUpdatedata('admin-backoffice/add-ddaep', fd);
                    if (data.succes.status == true) {
                        this.showAlert('Enregistrement effectué avec succès', 'success')
                        this.clearitem();
    
                    } else if (data.succes.status == false) {
                        this.showAlert(data.succes.message, 'error')
                    }
    
                } else {
                    const data = await api.createUpdatedata('admin-backoffice/update-ddaep/' + this.editedItem.id, fd);
                    if (data.succes.status == true) {
                        this.showAlert('Modification effectuée avec succès', 'success')
                        this.clearitem();
                        this.dialog = false;
    
                    } else if (data.succes.status == false) {
                        this.showAlert('Modification non effectuée', 'error')
                    }
    
                }
    
                this.readAll();
    
            },
    
            deletItem: async function (item) {
    
                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {
                    if (result.isConfirmed) {
    
                        
                const data = await api.readAll('admin-backoffice/delete-ddaep/' + item.id);
                if (data.succes.status == true) {
                    this.showAlert('Suppression effectuée avec succès', 'success')
                    this.clearitem();
                    this.dialog = false;
    
                } else if (data.succes.status == false) {
                    this.showAlert('Suppression non effectuée', 'error')
                }
                this.readAll();
    
                        
                    }
                })
               
            },
    
            clearitem() {
                this.editedItem.libelle_ddaep = '';
                this.editedItem.sigle = '';
                this.etat = false;
            },
    
            showAlert(textmessage, txticone) {
                this.$swal.fire({
                    position: 'top-end',
                    icon: txticone,
                    title: textmessage,
                    showConfirmButton: false,
                    timer: 1500
                })
            },
    
            getitem(item) {
                this.etat = true;
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },
    
            fetchData() {
                this.readAll();
            },
    
    
        },
    };
    </script>
    